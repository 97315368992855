html.custom-properties-disabled {

    .bg-grey-100 {
        background-color: #F7F7F7;
    }

    .border-grey-100 {
        border-color: #F7F7F7;
    }

    .text-grey-100 {
        color: #F7F7F7;
    }

    .bg-grey-200 {
        background-color: #BCBCBC;
    }

    .border-grey-200 {
        border-color: #BCBCBC;
    }

    .text-grey-200 {
        color: #BCBCBC;
    }

    .bg-grey-300 {
        background-color: #828282;
    }

    .border-grey-300 {
        border-color: #828282;
    }

    .text-grey-300 {
        color: #828282;
    }

    .bg-grey-400 {
        background-color: #4D4B44;
    }

    .border-grey-400 {
        border-color: #4D4B44;
    }

    .text-grey-400 {
        color: #4D4B44;
    }

    .bg-blue-100 {
        background-color: #0089E0;
    }

    .border-blue-100 {
        border-color: #0089E0;
    }

    .text-blue-100 {
        color: #0089E0;
    }

    .bg-blue-200 {
        background-color: #0071B9;
    }

    .border-blue-200 {
        border-color: #0071B9;
    }

    .text-blue-200 {
        color: #0071B9;
    }

    .bg-blue-300 {
        background-color: #0066A6;
    }

    .border-blue-300 {
        border-color: #0066A6;
    }

    .text-blue-300 {
        color: #0066A6;
    }

    .bg-black {
        background-color: black;
    }

    .border-black {
        border-color: black;
    }

    .text-black,
    .md\:text-black,
    .lg\:text-black {
        color: black;
    }

    .bg-white {
        background-color: white;
    }

    .border-white {
        border-color: white;
    }

    .text-white,
    .md\:text-white,
    .lg\:text-white {
        color: white;
    }

    .bg-red-100 {
        background-color: #E30613;
    }

    .border-red-100 {
        border-color: #E2001A;
    }

    .text-red-100 {
        color: #E2001A;
    }

    .shadow-md {
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
    }
}
