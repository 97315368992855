.toolbox-element {

    @apply mb-10 md:mb-24;

    &.toolbox-headline {
        @apply mb-0;
    }

    &.toolbox-separator {
        @apply mb-0;
    }

    &.toolbox-spacer {
        @apply mb-0;
    }

    &.toolbox-accordion {

        .toolbox-element {
            @apply mb-10 !important;
        }

    }

    &.toolbox-video {
        iframe {
            @apply w-full h-full;
        }
    }

    &.toolbox-container {
        @apply mb-0 overflow-hidden;

        picture img {
            @apply relative top-0 left-0;
        }

        .toolbox-element {
            &:last-child {
                @apply mb-0;
            }
        }
    }

    &.toolbox-anchor[id] {
        @apply mb-0 inline-block relative -top-20 sm:-top-28 pointer-events-none;
    }

    &.toolbox-teaser {
        @apply mb-10 !important;
    }

    &.toolbox-google-map {
        .embed-responsive-item {
            @apply top-0 left-0 right-0 bottom-0 absolute h-full;

            .info-window {
                strong {
                    @apply font-bold;
                }
                .google-map-route-planer {
                    @apply text-blue-200 mt-3;
                }
            }
        }
    }

}

.toolbox-swiper .swiper-pagination {
    @apply text-left bottom-5 ml-2.5 lg:ml-14;
}


.toolbox-swiper .swiper-button-disabled {
    @apply hidden;
}

.toolbox-swiper .swiper-pagination-wrap,
.toolbox-swiper .swiper-button-wrap {
    padding-bottom: 45.138888888888886%;
}

.toolbox-swiper .swiper-pagination .swiper-pagination-bullet {
    @apply w-3 h-3 bg-white ml-1.5 mr-1.5 pointer-events-auto cursor-pointer opacity-100 rounded;
}

.toolbox-swiper .swiper-pagination .swiper-pagination-bullet-active {
    @apply bg-blue-200;
}


/* Lightbox */
.lg-backdrop {
    @apply bg-white !important;
}


.lg-outer {

    .lg-item {
        background:url('../images/loader.gif') center center no-repeat !important;
        background-size: 52px 52px;
    }

    .lg-toolbar {
        @apply bg-transparent !important;

        .lg-close {
            @apply w-6 h-6 block mx-5 my-5;
            background-image: svg-load('../images/icons/close.svg', fill=black);

            &:after {
                content: none;
            }
        }
    }
}
