.slider {
    margin-top: -6rem;
}
.paragraph-image-claim-headline {
    font-weight: bold;
    line-height: 1.2;
    margin: 0;
}
.swiper-container {
    overflow: hidden;
}
.swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
}
