.btn {
    @apply text-lg py-3 px-5 rounded font-sans inline-flex items-center uppercase;

    &.arrow-left {
        &:before {
         content: '';
         @apply inline-block mr-3 w-5 h-5;
        }
    }

    &.arrow-right {
        &:after {
            content: '';
            @apply inline-block ml-3 w-5 h-5;
        }
    }

    &.primary {
        @apply bg-blue-200 hover:bg-blue-100 text-white border border-blue-200 hover:border-blue-100;

        &.arrow-right {
            &:after {
                background: svg-load('../images/icons/arrow-right.svg', fill=white) no-repeat;
            }
        }
    }

    &.small {
        @apply py-2 text-base;


        &.cart {
             @apply pl-2.5 pr-2.5;
        }
    }

    &.secondary {
        @apply bg-transparent hover:bg-blue-100 text-blue-200 hover:text-white border border-blue-200;

        &.arrow-right {

            &:after {
                background: svg-load('../images/icons/arrow-right.svg', fill=#0071B9) no-repeat;
            }

            &:hover {
                &:after {
                    background: svg-load('../images/icons/arrow-right.svg', fill=white) no-repeat;
                }
            }
        }

        &.arrow-left {

            &:before {
                background: svg-load('../images/icons/arrow-left.svg', fill=#0071B9);
            }

            &:hover {
                &:before {
                    background: svg-load('../images/icons/arrow-left.svg', fill=white) no-repeat;
                }
            }
        }
    }

    &.white {
        @apply bg-transparent hover:bg-white text-white hover:text-blue-200 border border-white hover:text-blue-200;

        &.arrow-right {

            &:after {
                background: svg-load('../images/icons/arrow-right.svg', fill=white) no-repeat;
            }

            &:hover {
                &:after {
                    background: svg-load('../images/icons/arrow-right.svg', fill=#0071B9) no-repeat;
                }
            }
        }

    }

    &.filter {
        @apply bg-grey-200 hover:bg-blue-200 text-white border border-grey-200 hover:border-blue-200 normal-case cursor-pointer;

        &.active {
             @apply bg-blue-200 text-white border border-blue-200 cursor-default;
        }

    }
}
