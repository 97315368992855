.news-entry {
    @screen sm {
        width: calc(50% - 20px);
    }

    @screen lg {
        width: calc(33.3333333% - 20px);
    }
}

.news-entry-image-overlay {
    background: rgba(0, 113, 185, .5);
}

.news-detail {
    .pimcore_tag_video {
        @apply w-full relative h-0;
        padding-bottom: 56.25%;
        background: #000;

        iframe {
            @apply absolute w-full h-full;
        }
    }
}