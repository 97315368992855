[x-cloak] {
    display: none !important;
}

*:focus {
    outline: none !important;
}

.compat-object-fit {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    img,
    picture {
        @apply hidden;
    }

}