@layer base {

    body {
        @apply text-black text-base;
    }

    h1, .h1, h2, .h2, h3, .h3, h4, .h4, p, .p, small, .small {
        @apply text-black;
    }

    h1, .h1 {
        @apply font-sans text-3xl md:text-5.5xl mb-6 md:mb-10;
    }

    h2, .h2 {
        @apply font-sans text-2.5 md:text-3xl mb-6 md:mb-10;
    }

    h3, .h3 {
        @apply font-sans text-2xl md:text-3xl mb-6 md:mb-10;
    }

    h4, .h4 {
        @apply font-sans text-xl md:text-2xl mb-5;
    }

    h5, .h5 {
        @apply font-sans text-lg md:text-xl mb-5 md:mb-4;
    }

    p, .p {
        @apply text-base md:text-lg mb-5;
    }

    .lead {
        @apply text-lg md:text-2xl leading-relaxed mb-5;
    }

    small, .small {
        @apply text-md md:text-base;
    }

    .hyphens-auto {
        hyphens: auto;
    }

}
