nav.main-nav {

    hyphens: auto;

    > ul {

        > li {

            &:last-child {
                @apply mr-0;
            }

            &:hover > a,
            &.is-open > a {
                @apply border-red-100 text-red-100;
                color: #0071b9;
                border-color: #0071b9;
            }

            > a {
                &:hover,
                &:active,
                &.active,
                &.is-open,
                &.mainactive {
                    @apply border-red-100 text-red-100;
                    color: #0071b9;
                    border-color: #0071b9;
                }
            }

            &:hover > div,
            &.is-open > div {
                @apply visible;
            }

            > ul {
                ul {
                    @apply invisible;
                }
            }

            &.mega-menu-item {

                > .mega-menu-wrap {
                    height: calc(100vh - theme('spacing.36') - theme('spacing.24') - theme('spacing.8'));

                    ul {
                        @apply w-72;
                        > li {
                            > ul {
                                width: calc(100% - theme('spacing.72'));
                                @apply hidden;
                            }
                            &:hover,
                            &.is-open {
                                > a {
                                    @apply text-red-100;
                                    color: #0071b9;
                                }
                                > ul {
                                    @apply flex visible;
                                }
                            }

                        }
                    }
                }
                &:hover,
                &.is-open {
                    .mega-menu-wrap {

                        ul {
                            > li {
                                &:first-child {
                                    > ul {
                                        @apply flex visible;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.flyout-menu-item {

                &:active,
                &:hover,
                &.is-open {
                    > .flyout-menu-wrap {
                        @apply visible;
                    }
                }

                > .flyout-menu-wrap {

                    > ul {

                        &.orientation-left {

                            > li > a svg {
                                @apply left-5 transform rotate-180;
                            }

                            > li > a {
                                @apply pl-14;
                            }

                            > li ul {
                                @apply -left-full;


                                &:before {
                                     @apply -right-3 left-auto;

                                 }

                                &:after {
                                     @apply -right-px left-auto;
                                 }

                            }
                        }

                        > li {


                            &:last-child {
                                @apply mb-0;
                            }

                            &:hover > a,
                            &.is-open > a {
                                @apply text-red-100;
                                color: #0071b9;

                                &.has-child {
                                    &:after {
                                        @apply text-red-100;
                                        color: #0071b9;
                                    }
                                }
                            }

                            > a {
                                &.active,
                                &:active,
                                &:hover {
                                    @apply text-red-100;
                                    color: #0071b9;

                                    &.has-child {
                                        &:after {
                                            @apply text-red-100;
                                            color: #0071b9;
                                        }
                                    }

                                }

                            }

                            > ul {

                                &:before {
                                    @apply absolute h-full w-6 -left-3 top-0 bg-white;
                                    content: '';
                                }

                                &:after {
                                    @apply bg-grey-200 absolute z-10 -left-px w-px h-5/6 top-1/2 transform -translate-y-1/2;
                                    content: '';
                                }

                                > li {

                                    &:last-child {
                                        @apply mb-0;
                                    }

                                    &:hover > a,
                                    > a {

                                        &.active,
                                        &:active,
                                        &:hover {
                                            @apply text-red-100;
                                            color: #0071b9;
                                        }

                                    }

                                }

                            }

                            &:hover > ul,
                            &.is-open > ul {
                                @apply visible;
                            }

                        }
                    }

                }

            }

        }


    }
}

.scrolled {
    .main-nav {
        > ul {
            > li {
                > a {
                    @apply text-black;
                }
            }
        }
    }
}

.mobile-nav-open {
    .mobile-overlay {
        .header {
            .icon-wrap {
                svg {
                    @apply text-black;

                    &.active,
                    &:active,
                    &:hover {
                        @apply text-primary;
                    }
                }
            }
        }
        .mobile-main-nav {
            max-height: calc(100vh - theme('spacing.36'));
        }
    }
}

.header-image {
    &-wrapper {
        height: 150px;

        &.startpage {
            height: 230px;
        }

        @screen sm {
            height: 315px;

            &.startpage {
                height: 528px;
            }
        }

        @screen md {
            height: 349px;

            &.startpage {
                height: 562px;
            }
        }

        @screen lg {
            height: 589px;

            &.startpage {
                height: 702px;
            }
        }
    }
}

.breadcrumb {
    a {
        @apply px-4 border-r border-grey-200 block hover:underline text-md first:pl-0 text-blue-200 last:text-black last:border-r-0;
    }
}

.container {
    @screen 2xl {
        padding-left: 5rem;
        padding-right: 5rem;
    }
}

.swal2-container {
    background: rgba(0,0,0,0.8) !important;
    text-align: left;
}

.swal2-container .swal2-popup {
    border-radius: 0 !important;
}

.swal2-html-container {
    text-align: left !important;
}
.swal2-html-container.text-center {
    text-align: center !important;
    margin-top: 25px;
}

html:lang(fr) .swal2-popup {
    @screen md {
        width: 560px;
    }
}

.header-gradient-text-white {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, transparent 100%);
}

.header-gradient-text-black {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.6) 0%, transparent 100%);
}

.link-underline {
    a {
        @apply hover:underline inline-block;
    }
}

.link-underline-block {
    a {
        @apply hover:underline block;
    }
}

.swiper-button-next:after,
.swiper-button-prev:after {
    @apply inline-block w-5 h-5 sm:h-10 sm:w-10 bg-no-repeat;
    content: '' !important;
}

.swiper-button-next:after {
    background-image: svg-load('../images/icons/chevron-right.svg', fill=#0071B9);
}

.swiper-button-prev:after {
    background-image: svg-load('../images/icons/chevron-left.svg', fill=#0071B9);
}

.hover-zoom .img-wrap {
    overflow: hidden;
}

.hover-zoom .img-wrap img {
    transition: transform 1s ease;
}

.hover-zoom:hover .img-wrap img {
    transform: scale(1.1);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility:    hidden;
    -ms-backface-visibility:     hidden;
}

/*
    price information on product detail page / spare parts page
    since the product_price macro is used on multiple places, apply this
    special styles only for the corresponding page wrapper
*/
.product-detail-page {
    .price-new-amount {
        @apply text-black text-2xl md:text-3xl mr-2;
    }
    .price-old-amount {
        @apply text-xl text-black font-bold mx-1;
    }
}
.product-spare-parts-list {
    .price-new-amount {
        @apply text-black font-bold block;
    }
}

.quantity-selector {

    .btn {
        @apply py-1 px-4 text-black border-grey-300 border hover:bg-blue-100 hover:text-white font-bold flex justify-center items-center;
        &.minus {
            @apply rounded-l rounded-r-none;
        }
        &.plus {
            @apply rounded-r rounded-l-none;
        }
    }

    input[type="number"] {
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

}

.cart-widget-products-container {
    max-height: calc(100vh - 230px);
}

/*
 special border-inside grid layout
 helpers: https://css-tricks.com/examples/nth-child-tester/
 */
.product-grid {
    @apply flex flex-wrap content-start overflow-x-hidden;
    > * {
        border: 1px solid transparent;
        border-top-color: theme('colors.grey.200');
        border-right-color: theme('colors.grey.200');
        /* helper border element if a row is not completely filled with items */
        &:last-child {
            position: relative;
            &:before {
                content: '';
                display: block;
                position: absolute;
                top: -1px;
                left: 0;
                width: 100vw;
                height: 1px;
                background-color: theme('colors.grey.200');
            }
        }
        /* < SM: two columns */
        @media (max-width: theme('screens.sm')) {
            & {
                @apply w-1/2;
            }
            &:nth-child(-n+2) {
                border-top-color: transparent;
                &:before {
                    display: none;
                }
            }

            &:nth-child(2n+2) {
                border-right-color: transparent;
            }

            &:last-child {
                &:nth-child(2n+1) {
                    border-right-color: theme('colors.grey.200');
                }
                &:nth-child(-n+2) {
                    border-right-color: transparent;
                }
            }
        }
        /* SM - LG: three columns */
        @media (min-width: theme('screens.sm')) and (max-width: theme('screens.lg')) {
            & {
                @apply w-1/3;
            }
            &:nth-child(-n+3) {
                border-top-color: transparent;
                &:before {
                    display: none;
                }
            }
            &:nth-child(3n+3) {
                border-right-color: transparent;
            }
            &:last-child {
                &:nth-child(3n+1), &:nth-child(3n+2) {
                    border-right-color: theme('colors.grey.200');
                }
                &:nth-child(-n+3) {
                    border-right-color: transparent;
                }
            }
        }
        /* > LG: fife columns */
        @media (min-width: theme('screens.lg')) and (max-width: theme('screens.xl')) {
            & {
                @apply w-1/4;
            }

            &:nth-child(-n+4) {
                border-top-color: transparent;

                &:before {
                    display: none;
                }
            }

            &:nth-child(4n+4) {
                 border-right-color: transparent;
            }
            &:last-child {
                &:nth-child(4n+1), &:nth-child(4n+2), &:nth-child(4n+3) {
                    border-right-color: theme('colors.grey.200');
                }
                &:nth-child(-n+4) {
                     border-right-color: transparent;
                }
            }
        }

        @media (min-width: theme('screens.xl')) {
            & {
                @apply w-1/5;
            }
            &:nth-child(-n+5) {
                border-top-color: transparent;
                &:before {
                    display: none;
                }
            }
            &:nth-child(5n+5) {
                border-right-color: transparent;
            }
            &:last-child {
                &:nth-child(5n+1), &:nth-child(5n+2), &:nth-child(5n+3), &:nth-child(5n+4) {
                    border-right-color: theme('colors.grey.200');
                }
                &:nth-child(-n+5) {
                    border-right-color: transparent;
                }
            }
        }
    }
}
