@layer base {

    [type='text'],
    [type='email'],
    [type='url'],
    [type='password'],
    [type='number'],
    [type='date'],
    [type='datetime-local'],
    [type='month'],
    [type='search'],
    [type='tel'],
    [type='time'],
    [type='week'],
    [multiple],
    textarea,
    select {
        @apply border-grey-200 border w-full text-lg text-black;
    }

    [type='text']:focus, [type='email']:focus, [type='url']:focus, [type='password']:focus, [type='number']:focus, [type='date']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='week']:focus, [multiple]:focus, textarea:focus, select:focus {
        @apply border-blue-200 ring-blue-200;
    }

    [type='checkbox']:focus,[type='radio']:focus {
        @apply ring-blue-200;
    }

    input:disabled, select:disabled {
        @apply bg-grey-100 text-grey-300;
    }

}

.form-builder-wrapper .form-success-wrapper,
.form-builder-wrapper .message-success {
    @apply text-lg text-blue-200 p-3 border-2 border-blue-200;
}

.form-builder-wrapper .validation-error {
    @apply text-error mt-2 block w-full;
}

label.required::after {
    content: '*';
    @apply ml-1.5 text-red-100 inline-block;

}

select.product-multi-selection{
    background-image: svg-load('../images/icons/triangle-down.svg', fill=#0089E0);
    background-size: 0.7rem;
    background-position: right 0.7rem center;
}
