.wysiwyg {

    a:not(.btn) {
        @apply text-blue-200;
    }

    ol, ul {
        @apply list-outside mb-10;
    }

    > ul {
        @apply list-none mb-5;

        > li {
            @apply relative pl-6;

            &:before {
                content: '';
                @apply inline absolute top-0 left-0 w-2.5 h-2.5 bg-blue-200 top-2 rounded-sm;
            }

            > ul {
                @apply my-4;

                > li {
                    @apply relative pl-6;

                    &:before {
                        content: '—';
                        @apply inline absolute left-0 text-blue-200 text-xs top-1;
                    }
                }

            }
        }
    }

    ol {
        @apply list-none;
        counter-reset: item;

        li {
            @apply relative pl-6;

            &:before {
                content: counter(item) '.';
                counter-increment: item;
                @apply font-bold inline absolute top-0 left-0;
            }
        }
    }

    table {
        @apply mb-5 border border-grey-200 min-w-full;

        tr {
            @apply border-b border-grey-200;


            &:nth-child(even) {
                @apply bg-grey-100;
            }

            td, th {
                @apply text-left text-black px-4 py-3;
            }

            th {
                @apply font-bold text-lg bg-grey-100;
            }

            td {
                @apply text-base;
            }

        }

    }

}