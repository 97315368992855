@import url("//hello.myfonts.net/count/3d10a8");

$fontPath: theme('fontPath');

@font-face {
    font-family: 'ff-mark-w1g-book';
    src: url('$fontPath/ff-mark-w1g-book/font.woff2') format('woff2'), url('$fontPath/ff-mark-w1g-book/font.woff') format('woff');
}

@font-face {
    font-family: 'ff-mark-w1g-bold';
    src: url('$fontPath/ff-mark-w1g-bold/font.woff2') format('woff2'), url('$fontPath/ff-mark-w1g-bold/font.woff') format('woff');
}
